@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;
@use '@content' as *;

.question-wrapper {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: max-content;
  box-shadow: var(--elevation-1);
  padding: 14px 14px 14px 10px;
  outline: 2px solid #f6f6f6;
  outline-offset: -1.5px;
  gap: 10px;
  border-radius: var(--cornerradius12);

  @include min-1440-break {
    border-radius: var(--cornerradius14);
    padding: 16px 16px 16px 12px;
    gap: 12px;
  }

  @include min-1920-break {
    border-radius: var(--cornerradius16);
    padding: 18px 18px 18px 14px;
    gap: 14px;
  }
}

.question {
  display: flex;
  align-items: center;
  gap: 10px;

  @include min-1440-break {
    gap: 12px;
  }

  @include min-1920-break {
    gap: 14px;
  }

  .icon {
    @include singleFixedSize(24px);
    color: var(--global-purple600);

    @include min-1440-break {
      @include singleFixedSize(28px);
    }

    @include min-1920-break {
      @include singleFixedSize(32px);
    }
  }

  .title {
    @include typography-base;
    @include weight-semi-bold;
    color: var(--cst-dropitem-text-default);

    @include min-1440-break {
      @include typography-m;
    }

    @include min-1920-break {
      @include typography-l;
    }
  }
}

.question-content {
  padding-left: 34px;

  @include min-1440-break {
    padding-left: 40px;
  }

  @include min-1920-break {
    padding-left: 46px;
  }

  .subtitle {
    @include typography-s;
    @include weight-medium;
    color: var(--cst-cards-category-subtitle-default);

    @include min-1440-break {
      @include typography-base;
    }

    @include min-1920-break {
      @include typography-m;
    }
  }
}

.img-container {
  display: flex;
  position: relative;
  overflow: hidden;
  margin-bottom: 12px;
  @include containerPaddings;
  @include fixedSize(100%, 160px);
  border-radius: var(--cornerradius8);

  @include min-1440-break {
    @include fixedSize(100%, 178px);
    border-radius: var(--cornerradius10);
  }

  @include min-1920-break {
    @include fixedSize(100%, 222px);
  }

  .img {
    object-fit: cover;
    width: 100%;
    height: auto;
  }
}
